package hu.mkik.vb.portal.ui.proceeding.history.pages

import hu.mkik.vb.portal.model.ProceedingEvent
import hu.mkik.vb.portal.ui.component.participationByAccountColumn
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.history.modals.proceedingEventDetails
import hu.mkik.vb.portal.ui.proceedingEventService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import kotlinx.datetime.Instant

fun Z2.history() =
    tablePage<ProceedingEvent> {

        header {
            title { title(currentProceeding.proceeding.caseNumber, strings.history) }
        }

        fixRowHeight = false
        rowId = { it.uuid }
        query = { proceedingEventService.query(currentProceeding.proceeding.uuid, Instant.fromEpochSeconds(0)) }

        with(ProceedingEvent()) {
            schematicColumn { this.createdAt }
            participationByAccountColumn(currentParticipations) { this.createdBy }
            schematicColumn { this.type }
            schematicColumn { this.documentName }
            schematicColumn { this.additionalInfo }
            actionColumn {
                action {
                    label = strings.details
                    handler = { proceedingEventDetails(it) }
                }
                initialSize = "min-content"
            }
        }
    }


