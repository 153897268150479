package hu.mkik.vb.portal.ui.proceeding.finance.components

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.finance.OutgoingRequirement
import hu.mkik.vb.portal.model.finance.ProceedingFeeCalculation
import hu.mkik.vb.portal.model.finance.ProceedingFinanceBundle
import hu.mkik.vb.portal.ui.component.participationColumn
import hu.mkik.vb.portal.ui.component.titledTableContainer
import hu.mkik.vb.portal.ui.proceeding.finance.logic.ProceedingFeeCalculator
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.util.UUID

fun Z2.socialSecurityContribution(bundle: ProceedingFinanceBundle, counter : Boolean) =
    titledTableContainer(strings.socialSecurityContribution) {
        val calculation = ProceedingFeeCalculator(bundle.proceeding, bundle.settings, bundle.participations, counter).result

        table<OutgoingRequirement> {
            fixRowHeight = false
            noScroll = true
            rowId = { it.uuid }
            data = calculation.socialSecurityContributionRequirements
            with(OutgoingRequirement()) {

                participationColumn(bundle.participations) { participation }.initialSize = "1fr"

                column {
                    label = strings.retired
                    render = { req -> if (bundle.participations.first { req.participation == it.uuid }.retired) icon(browserIcons.check) }
                    initialSize = "5em"
                }

                column {
                    label = strings.honorarium
                    render = { req -> + honorarium(calculation, bundle, req.participation).localized }
                    initialSize = "min-content"
                }

                schematicColumn { requiredAmount } initialSize "min-content" label strings.szocho
            }
        }
    }

private fun honorarium(calculation: ProceedingFeeCalculation, bundle: ProceedingFinanceBundle, participation: UUID<Participation>) : Long {
    return if (bundle.settings.chairmanRole == bundle.participations.first { it.uuid == participation }.proceedingRole) {
        calculation.chairmanHonorarium
    } else {
        calculation.arbitratorHonorarium
    }
}