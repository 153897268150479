package hu.mkik.vb.portal.ui.administration.securityPolicy

import hu.mkik.vb.portal.ui.administration.securityPolicy.EditSecurityPolicy.edit
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.isSecurityOfficer
import hu.mkik.vb.portal.ui.isTechnical
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.mkik.vb.portal.ui.util.section
import hu.simplexion.z2.auth.authAdminService
import hu.simplexion.z2.auth.model.SecurityPolicy
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.scrolledBoxWithLabel
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.locales.localeCapitalized

object EditSecurityPolicy : NavRouter(strings.securityPolicy, icons.securityPolicy, useParentNav = true, default = { edit() }) {

    private fun Z2.edit() =
        surfaceContainerLowest(borderOutline) {

            title(
                strings.securityPolicy.localeCapitalized,
                if (! isSecurityOfficer) strings.onlyAdminMayChange else null,
                true
            )

            div {
                loading {
                    val policy = authAdminService.getPolicy()

                    grid(pt8) {
                        style.maxWidth = 900.px
                        gridTemplateColumns = "repeat(auto-fill, minmax(288px, 1fr))"
                        columnGap = 24.px

                        loginSettings(policy)
                        passwordSettings(policy)
                        sessionSettings(policy)
                    }

                    if (isSecurityOfficer || isTechnical) {
                        filledLaunchButton(strings.save) {
                            authAdminService.setPolicy(policy)
                            snackbar(strings.saveSuccess)
                        }
                    }
                }
            }

        }
}

fun Z2.passwordSettings(policy: SecurityPolicy) =
    section(strings.password) {
        with(policy) {
            // field { passwordChangeInterval }
            // field { passwordHistoryLength }
            field { passwordLengthMinimum }
            field { uppercaseMinimum }
            field { digitMinimum }
            field { specialCharacterMinimum }
            field { sameCharacterMaximum }
            div {
                scrolledBoxWithLabel(strings.passwordStrength) {
                    field { minEntropy }
                } css mb16
            }
        }
    }

fun Z2.loginSettings(policy: SecurityPolicy) =
    section(strings.login) {
        with(policy) {
            field { twoFactorAuthentication } css h56
            field { sessionActivationInterval }
            field { maxFailedAuths }
        }
    }

fun Z2.sessionSettings(policy: SecurityPolicy) =
    section(strings.session) {
        with(policy) {
            field { sessionExpirationInterval }
        }
    }