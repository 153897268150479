package hu.mkik.vb.portal.ui.bank.pages

import hu.mkik.vb.portal.model.finance.BankTransaction
import hu.mkik.vb.portal.ui.bank.modals.editTransaction
import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.closedTransactions() =
    tablePage<BankTransaction> {

        header {
            title { text { strings.closedTransactions.localeCapitalized } }
        }

        rowId = { it.uuid }
        query = { bankService.closedTransactions() }
        doubleClickFun = { editTransaction(it) }

        with(BankTransaction()) {
            schematicColumn { valueDate }
            schematicColumn { type } initialSize "min-content"
            schematicColumn { amount }
            schematicColumn { remainingAmount }
            schematicColumn { peerName }
            schematicColumn { peerAccount }
            schematicColumn { additionalInfo }
            actionColumn {
                action {
                    label = strings.edit
                    handler = { editTransaction(it) }
                }
                initialSize = "min-content"
            }
        }
    }