package hu.mkik.vb.portal.ui.bank.pages

import hu.mkik.vb.portal.model.finance.BankStatement
import hu.mkik.vb.portal.ui.bank.modals.uploadStatement
import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.statements() =
    tablePage<BankStatement> {

        header {
            title { text { strings.statements.localeCapitalized } }
            action(strings.upload) { uploadStatement() }
        }

        rowId = { it.statementId }
        query = { bankService.statements() }

        with(BankStatement()) {
            schematicColumn { statementId }
            schematicColumn { startDate }
            schematicColumn { endDate }
            schematicColumn { creditCount }
            schematicColumn { creditAmount }
            schematicColumn { remainingCredit }
            schematicColumn { debitCount }
            schematicColumn { debitAmount }
            schematicColumn { remainingDebit }
        }
    }